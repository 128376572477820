export const environment = {
  production: true,
  enableAuthGuard: true,
  current_user: null,
  baseUrl: 'https://api.awaken180weightloss.com/api',
  client_facing_app_url: 'https://client-api.awaken180weightloss.com/api/v1/internal_controllers',
  emojiApiKey: 'e994e0b7cbd3383a7a8b787e47ceef7d2184c741',
  esignGenieSecret: '9b2189199c754193bd3f8b9765b2c9fa',
  teamFacingApiKey: '15714446-9896-4805-bf9e-4ed7ced94262'
};
