import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignedDocumentsService} from './signed-documents.service';
import {SignedDocument} from '../../models/signed_document';
import {GlobalsService} from '../../globals.service';
import {IBasicClient} from '../../models/client';
import {ClientDocService} from '../../pages/clients/client-detail/providers/client-doc/client-doc.service';
import * as _ from 'lodash';
import {IMergedDocument} from '../../models/merged_documents';
import { ToastService } from 'src/app/providers/toast.service';

@Component({
  selector: 'a180-signed-document-selector',
  templateUrl: './signed-document-selector.component.html',
  styleUrls: ['./signed-document-selector.component.scss'],
})
export class SignedDocumentSelectorComponent implements OnInit {
  @Input() client: IBasicClient;
  @Output() continueProcessing = new EventEmitter<boolean>()
  selectedDocuments: SignedDocument[] = [];
  searchValue: string;

  constructor( public signedDocumentsService: SignedDocumentsService,
               private clientDocService: ClientDocService,
               private toastService: ToastService,
               private globals: GlobalsService ) { }

  ngOnInit() {
    if (this.client && this.client.name) {
      this.signedDocumentsService.searchForSignedDocuments(this.client.name.split(" ")[0]).subscribe()
    }
  }


  /**
   * Searches the database for signed documents based on the user's search query.
   * @param event
   */
  searchForSignedDocuments(event: any): void {
    if (event?.target?.value !== "") {
      if (event.target.value.length < 3) {
        this.toastService.handleResponse('Please enter a name or email that is at least 3 characters long.', false, 'warning')
        return
      }
      let signedDocumentIds = ""
      let signedDocuments: IMergedDocument[] = _.filter(this.clientDocService.getMergedDocuments(), {className: "SignedDocument", canBeViewed: true})
      if (signedDocuments) {
        signedDocumentIds = signedDocuments.map(doc => doc.external_document_id).join(",")
      }
      this.signedDocumentsService.searchForSignedDocuments(event.target.value, true, signedDocumentIds).subscribe()
    }
  }

  continue(shouldLinkToClient): void {
    if (shouldLinkToClient && this.signedDocumentsService.selectedDocuments.length) {
      this.signedDocumentsService.selectedDocuments.map(doc => doc.client_id = this.client.id)
      this.signedDocumentsService.updateMultiple(this.signedDocumentsService.selectedDocuments)
        .then(() => {
          this.continueProcessing.emit(true)
        })
        .catch(error => this.globals.handleResponse(error.error, true))
    } else {
      this.continueProcessing.emit(false)
    }
  }
}
