import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
// import {ModalController, NavParams} from '@ionic/angular';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';
import {ExternalDocumentsService} from './e-signature.service';
import {ExternalDocument, IExternalDocument, IExternalDocumentField} from '../../models/external_document';
import {IClient} from '../../models/client';
import {ISignedDocument, SignedDocument} from '../../models/signed_document';
import * as _ from 'lodash';
import {listAnimation, listStagger} from '../../animations/slideInListAnimation';
import * as moment from 'moment';
import {TransactionsProvider} from '../../pages/clients/client-detail/providers/finances/transactions.service';
import {ClientDocService} from '../../pages/clients/client-detail/providers/client-doc/client-doc.service';
import {GlobalsService} from '../../globals.service';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import { IUser } from 'src/app/models/user';
import { AuthService } from 'src/app/auth/auth.service';
import { SendForSignatureService } from '../send-for-signature/send-for-signature.service';
import { MissingFieldsFormComponent } from '../send-for-signature/missing-fields-form/missing-fields-form.component';
import { fadeInOutUpDown } from 'src/app/animations/fadeInOutUpDown';
import { ToastService } from 'src/app/providers/toast.service';
import { ShowDocumentModal } from 'src/app/pages/clients/client-detail/documents/show-document.modal';
import { ESignatureCardComponent } from './e-signature.card';
import { NewDocumentModal } from '../image-upload/new-document.modal';
import { S3_Service } from 'src/app/providers/S3-service.service';
import { IClientDoc } from 'src/app/models/client_doc';


@Component({
  selector: 'e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.scss'],
  animations: [
    listAnimation,
    listStagger,
    fadeInOutUpDown
  ]
})
export class ESignatureComponent implements OnInit, OnDestroy {
  @Input() client: IClient
  @ViewChild('template', {static: true}) template;
  @ViewChild('missingFieldsForm') missingFieldsForm: MissingFieldsFormComponent;
  iframeCode: SafeHtml;
  user: IUser;
  selectedDocument: ExternalDocument;
  signedDocuments: ISignedDocument[];
  // client: IClient;
  firstName: string;
  lastName: string;
  email: string;
  pdfSrc: Uint8Array;
  base64Pdf: string;
  displayModal: boolean;
  formattedUrl: string | SafeUrl;
  formValues: any;


  constructor(private modalCtrl: ModalController,
              public externalDocumentService: ExternalDocumentsService,
              private transactionService: TransactionsProvider,
              public sendForSignatureService: SendForSignatureService,
              private toastService: ToastService,
              private globals: GlobalsService,
              private s3: S3_Service,
              private authService: AuthService,
              private clientDocService: ClientDocService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.client) {
      this.signedDocuments = this.clientDocService.getSignedDocuments()
      let name = this.client.name
      if (name) {
        let splitName = name.split(" ")
        this.firstName = splitName[0]
        this.lastName = splitName.slice(1, splitName.length).join(" ")
      }
      this.email = this.client.user?.email
      this.user = this.client.user
    }
    this.fetchDocuments(null)

    console.log(this)
  }

  ngOnDestroy() {
    console.log("destroy")
    this.client = null
    this.sendForSignatureService.setMissingFields([])
  }


  /**
   * Calls service to load documents from the database.
   */
  fetchDocuments(event=null) {
    this.externalDocumentService.setExternalDocuments([])
    this.externalDocumentService.fetchDocuments('in_person').subscribe(documents => {
      documents.forEach(document => {
        let signedDoc = _.find(this.signedDocuments, {external_document_id: document.id})
        if (signedDoc) {
          signedDoc = new SignedDocument(signedDoc)
          document.highestStatus = signedDoc.highestStatus()
          document.signed_at = signedDoc.signed_at
          document.signed_document_id = signedDoc.id
        }
      })
      this.externalDocumentService.setExternalDocuments(documents)
      if (event) event.target.complete()
    })
  }

  handleFormSubmission(event: any) {
    this.formValues = event
  }

  getFieldValue(field: IExternalDocumentField): string {
    return this.formValues[field.form_helper]
  }

  async selectDocument(selectedDocument: ExternalDocument) {
    this.selectedDocument = selectedDocument
    const clientId = this.client?.id || null
    this.externalDocumentService.fetchRequiredFields(clientId, selectedDocument.id).subscribe(fields => {
      console.log(fields);
      console.log(this.missingFieldsForm);

      this.sendForSignatureService.setMissingFields([{ document_id: selectedDocument.id, fields: fields }])
    })
  }

  openPdfDocument() {
    this.externalDocumentService.fetchPdfOfExternalDocument(this.selectedDocument, this.formValues).subscribe(
      data => {
        this.base64Pdf = data.data
        this.displayPdfModal()
      },
      err => {
        this.toastService.handleResponse(err.error.message, true, 'danger', err.error.header)
      }
    )
  }


  async displayPdfModal(): Promise<any> {
    const b64toBlob = (base64, type = 'application/octet-stream') =>
      fetch(`data:${type};base64,${base64}`).then(res => res.blob())

    const type = 'application/pdf'
    const file = await b64toBlob(this.base64Pdf, type)
    const base64file = atob(this.base64Pdf)
    const fileURL = URL.createObjectURL(file);

    const modal = await this.modalCtrl.create({
      component: ShowDocumentModal,
      componentProps: {
        document_name: this.selectedDocument.name,
        document_url: fileURL,
        canSend: true,
        fileType: 'pdf',
        base64file
      },
      cssClass: 'modal-fullscreen'
    })
    await modal.present();
    const { data } = await modal.onDidDismiss()

    console.log(data);

    if (data?.sendDocument) {
      this.externalDocumentService.sendDocumentToClient(this.selectedDocument, this.formValues).subscribe(
        data => {
          console.log(data)
          this.toastService.handleResponse('Document sent to client', false, 'success')
        },
        err => {
          console.log(err)
          this.toastService.handleResponse(err.error.message, true, 'danger')
        }
      )
    }
  }

  async generateAndSaveClientDoc(file: string) {
    // const document = {
    //   name: this.selectedDocument.name,
    //   client_id: this.client.id,
    //   visible_to_client: false,
    //   metadata: {
    //     document_id: this.selectedDocument.id,

    //   }
    // }
    // this.clientDocService.create(this.selectedDocument).subscribe()
    // let key = this.client?.id ? `clients/documents/000/000/${this.client?.id}/original` : `pdfs`
    // const fileType = '.pdf'

    // key += `/${this.selectedDocument.name}---${Date.now()}`
    // if (fileType) key += fileType

    // const params = {
    //   Key: key,
    //   Body: file,
    //   Bucket: 'awaken180-assets',
    //   ContentEncoding: 'base64',
    //   ContentType: fileType
    // };

    // // this.canSave = false;
    // console.log(key);


    // try {
    //   const data = await this.s3.upload(params)
    //   const doc: IClientDoc = {
    //     name: this.selectDocument.name,
    //     url: `${this.s3.config.bucket}${data.key}`,
    //     client_id: this.client?.id,
    //     key: data.key,
    //     fileKey: data.key,
    //     visible_to_client: false
    //   }

    //   await this.modalCtrl.dismiss({
    //     shouldRefresh: true,
    //     image: doc
    //   })

    // } catch (e) {
    //   this.toastService.handleResponse('Error uploading the image!', true)
    // }
  }





  /**
   * Renders iframe for client to sign electronically by forming the URL based on the chosen externalDocument.
   * @param externalDocument
   */
  async displayDocument(externalDocument: IExternalDocument) {
    let document = new ExternalDocument(externalDocument)
    console.log(document);

    let url = document.url
    let client = this.client
    console.log(document)

    for (let field of document.external_document_fields) {
      console.log(field, this.getFieldValue(field));
      url = url.replace(field.url_replace, (this.getFieldValue(field) || ''))
    }

    console.log(url);

    this.formattedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    this.selectedDocument = document

    // if (document.name.includes("Policy Packet") ||
    //     ['Photo Release Form'].includes(document.name)) {
    // }

    if (document.name.includes('Investment Structure')) {
      if (client) {
        let totalPaid = this.transactionService.getTotalPaid()
        let weeksPurchased = this.transactionService.getPurchasedWeeks()

        if (!totalPaid || !weeksPurchased) {
          this.transactionService.fetchTransactions(this.client.id).subscribe((trans) => {
            console.log(trans)
            console.log(this.transactionService.getTotalPaid())
            this.replaceValuesAndProcess(url, document)
          })
        } else {
          this.replaceValuesAndProcess(url, document)
        }
      } else {
        this.replaceValuesAndProcess(url, document)
      }
    }

    const modal = await this.modalCtrl.create({
      component: ESignatureCardComponent,
      componentProps: {
        selectedDocument: this.selectedDocument,
        formattedUrl: this.formattedUrl,
        isModal: true,
        client: null
      },
      cssClass: 'modal-fullscreen'
    })
    await modal.present()

    // this.displayModal = true;
  }


  replaceValuesAndProcess(url, document): void {
    let client = this.client

    if ( client ) {
      url = this.replaceUrlValues(url, "INSERT_WEIGHT_HERE", client.current_weight)
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_HERE", client.current_body_fat)
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_GOAL_HERE", client.body_fat_target2)
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_HERE", client.current_visceral_fat)
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_GOAL_HERE", client.visceral_fat_target2)
      url = this.replaceUrlValues(url, "INSERT_RECOMMENDED_WEEKS_HERE", client.recommended_weeks)
      url = this.replaceUrlValues(url, "INSERT_WEEKS_PURCHASED_HERE", this.transactionService.getPurchasedWeeks())
      url = this.replaceUrlValues(url, "INSERT_AMOUNT_PAID_HERE", this.transactionService.getTotalPaid())
      url = this.replaceUrlValues(url, "INSERT_CONSULTANT_NAME_HERE", client.consultant)
      this.selectedDocument = document
      this.createOrUpdateSignedDocument('viewed_at')
    } else {
      url = this.replaceUrlValues(url, "INSERT_WEIGHT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_GOAL_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_GOAL_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_AGE_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_RECOMMENDED_WEEKS_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_WEEKS_PURCHASED_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_AMOUNT_PAID_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_CONSULTANT_NAME_HERE", "")
      this.selectedDocument = document
    }

    url += '&hideFormFields=true'

    this.formattedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    console.log(url, this.formattedUrl)
  }


  /**
   * Creates a signed document if one does not already exist.
   */
  createOrUpdateSignedDocument(field: 'viewed_at'|'sent_at'|'signed_at') {
    console.log(this.selectedDocument)

    let signedDocument = new SignedDocument({
      external_document_id: this.selectedDocument.id,
      client_id: this.client.id,
      id: this.selectedDocument.signed_document_id
    })

    signedDocument[field] = moment().format()

    if (this.selectedDocument.highestStatus == null) {
      this.clientDocService.createSignedDocument(signedDocument).subscribe(
        document => {
          this.signedDocuments.push(document)
          this.fetchDocuments()
        },
        err => this.globals.handleResponse(err.error, true)
      )
    } else {
      this.clientDocService.updateSignedDocument(signedDocument).subscribe(() => {
        this.fetchDocuments()
      })
    }
  }


  /**
   * Replaces the URL values with the provided value or replaces the value with an empty string
   * @param url
   * @param field
   * @param value
   */
  replaceUrlValues(url, field, value): string {
    if (value) {
      url = url.replace(field, value.toString())
    } else {
      url = url.replace(field, "")
    }
    return url
  }

  async handleDocumentSigning(docWasSigned: boolean) {
    if (docWasSigned) {
      let modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: "Are you sure?",
          subtitle: "This will mark the document as signed in our system.  Only proceed if the document was signed successfully",
          type: "confirm"
        },
        cssClass: "small-modal"
      })
      await modal.present()
      modal.onDidDismiss().then(data => {
        console.log(data)
         if (data && data.data && data.data == 'yes') {
           console.log("signed it")
           this.createOrUpdateSignedDocument('signed_at')
           this.selectedDocument = null
         }
      })
    } else {
      // this.selectedDocument = null
      this.displayModal = false;
    }
  }
}
