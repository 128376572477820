<ion-modal [isOpen]="displayModal" class="modal-fullscreen" #modal>
  <ng-template>
    <ion-content [fullscreen]="true">
      <ion-card class="bg-white fast p-0 m-0" *ngIf="selectedDocument">
        <e-signature-card
          [selectedDocument]="selectedDocument"
          [formattedUrl]="formattedUrl"
          [client]="client"
          (close)="handleDocumentSigning($event)"
        ></e-signature-card>
      </ion-card>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-grid>
  <ion-row>
    <ion-col>
      <a180-common-card
        title="Select a Form to Sign:"
        helpText="Pull down to refresh">
        <ion-refresher slot="fixed" (ionRefresh)="fetchDocuments($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-list class="mt-4" [@listStagger]='(externalDocumentService.externalDocuments$ | async)?.length'>
          <ion-item-sliding class="documentRowSlider shaded-slider" *ngFor="let item of externalDocumentService.externalDocuments$ | async">

              <ion-item button class="documentRow ion-text-wrap" style="background:none;" [disabled]="item.signed_at">
                <ion-icon
                  *ngIf="client"
                  [name]="item.signed_at ? 'checkmark-circle' : 'close-circle'"
                  [color]="item.signed_at ? 'success' : 'danger'"
                  slot="start"
                ></ion-icon>
                <ion-label (click)="selectDocument(item)" class="pointer">
                  <div class="flex align-items-center">
                    <h2 class="pb-1">
                      <strong class="pr-3">
                        {{ item.name | titleize | capitalize }}
                      </strong>
                    </h2>
                  </div>
                  <p *ngIf="client && item.highestStatus">
                    {{item.highestStatus.field | titleize | capitalize}}: {{item.highestStatus.date | date:'short'}}
                  </p>
                </ion-label>
              </ion-item>

          </ion-item-sliding>
        </ion-list>
      </a180-common-card>

      <ion-row @fadeInOutUpDown *ngIf="selectedDocument">
        <ion-col>
          <a180-common-card title="Enter the Following Values:" helpText="Populate values for the document">
            <a180-missing-fields-form
              [allowNotes]="false"
              [submitButtonText]="'Set Attributes'"
              (form_submission)="handleFormSubmission($event)"
              #missingFieldsForm
            ></a180-missing-fields-form>
            <ion-row>
              <ion-col class="ion-text-center">
                <ion-button
                  color="danger"
                  [disabled]="!formValues"
                  (click)="displayDocument(this.selectedDocument)"
                >ESign Document</ion-button>
              </ion-col>
              <ion-col class="ion-text-center">
                <ion-button
                  [disabled]="!formValues"
                  (click)="openPdfDocument()"
                >View PDF</ion-button>
              </ion-col>
            </ion-row>
          </a180-common-card>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="displayModal">
        <ion-col>
          <ion-card class="bg-white fast p-0 m-0" *ngIf="selectedDocument">
            <e-signature-card
              [selectedDocument]="selectedDocument"
              [formattedUrl]="formattedUrl"
              [client]="client"
              (close)="handleDocumentSigning($event)"
            ></e-signature-card>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
