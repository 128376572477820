import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {GlobalsService} from '../../globals.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {IExternalDocument, IMissingFieldError} from '../../models/external_document';
import {switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { IPdfDocument } from 'src/app/models/pdf_document';

//TODO: Understand garbage collection of this service! (as well as send for signature service)

@Injectable()
export class ExternalDocumentsService implements OnDestroy {
  base_url: string;

  private externalDocuments = new BehaviorSubject(<IExternalDocument[]>[])
  externalDocuments$ = this.externalDocuments.asObservable()

  constructor(private globals: GlobalsService,
              private http: HttpClient) {
    this.base_url = this.globals.base_url;
  }

  ngOnDestroy() {
    console.log("DESTROY")
    this.setExternalDocuments([])
  }


  /**
   * Loads all external documents from the database
   */
  fetchDocuments(use_case: 'to_embed'|'in_person', location_name: string = null): Observable<IExternalDocument[]> {
    let url = `${this.base_url}/external_documents?purpose=${use_case}&location_name=${location_name}`
    return this.http.get<IExternalDocument[]>(url).pipe(
      tap(documents => {
        console.log(documents)
        this.setExternalDocuments(documents)
      })
    )
  }


  fetchRequiredFields(client_id: number, document_id: number): Observable<IMissingFieldError[]> {
    let url = `${this.base_url}/clients/${client_id}/external_documents/${document_id}/fetch_required_fields`
    return this.http.get<IMissingFieldError[]>(url)
  }


  setExternalDocuments(external_documents: IExternalDocument[]): void {
    this.externalDocuments.next(Object.assign([], external_documents))
  }


  /**
   * Sends the client the {@link selectedDocuments} to send.  If the team member adds formValues, the client is updated
   * and the novel fields and new values are returned for use.
   * @param client_id
   * @param external_document_ids
   * @param formValues
   */
  sendForSignature(client_id: number, external_document_ids: number[], formValues: any): Observable<{errors?: string[], updatedFields?: any}> {
    let url = `${this.base_url}/clients/${client_id}/external_documents/send_for_signature`
    return this.http.post<{errors?: string[], updatedFields?: any}>(url, {form_values: formValues, document_ids: external_document_ids})
  }

  generateFormParams(externalDocument: IExternalDocument, formValues): IPdfDocument {
    return {
      name: externalDocument.name,
      version: 1,
      recipient_email: formValues['user.email'],
      recipient_name: `${formValues['client.first_name']} ${formValues['client.last_name']}`,
      metadata: formValues
    }
  }


  fetchPdfOfExternalDocument(externalDocument: IExternalDocument, formValues): Observable<{data: string}> {
    const url = `${this.globals.base_url}/external_documents/${externalDocument.id}/pdf_documents/pdf_download`;
    const data = { pdf_document: this.generateFormParams(externalDocument, formValues) }
    return this.http.post<{data: string}>(url, data, { headers: { 'Api-Key': environment.teamFacingApiKey } } )
  }

  sendDocumentToClient(externalDocument: IExternalDocument, formValues): Observable<void> {
    const url = `${this.globals.base_url}/external_documents/${externalDocument.id}/pdf_documents/send_to_recipient`;
    const data = { pdf_document: this.generateFormParams(externalDocument, formValues) }
    return this.http.post<void>(url, data, { headers: { 'Api-Key': environment.teamFacingApiKey } } )
  }
}
