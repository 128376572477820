<form #sendForSignatureForm="ngForm" name="sendForSignatureForm">
  <ion-list>
    <ng-container *ngFor="let field of sendForSignatureService.allFields; let i = index;">
      <ion-item class="better-validation">
        <ion-label color="primary">{{field.message}}</ion-label>
        <ion-input *ngIf="field.type != 'select'"
                   [required]="!field.optional"
                   [(ngModel)]="missingFieldValues[field.required]"
                   [type]="field.type"
                   [name]="field.message"
                   [value]="field.value"
                   #tempRef=ngModel
                   (ionInput)="updateFormValues($event)"
        ></ion-input>
        <ion-select *ngIf="field.type == 'select'"
                    [required]="!field.optional"
                    [(ngModel)]="missingFieldValues[field.required]"
                    [name]="field.message"
                    [value]="field.value"
                    #tempRef=ngModel>
          <ion-select-option *ngFor="let option of field.options" [value]="option">{{option}}</ion-select-option>
        </ion-select>
        <small *ngIf="field.value" class="text-muted">Rec. Value</small>
      </ion-item>
      <small class="text-left ion-padding-start" *ngIf="field.hint">
        Hint: This is what we have on file: {{field.hint}}
      </small>
    </ng-container>
    <ion-item *ngIf="allowNotes">
      <ion-label color="primary">Notes</ion-label>
      <ion-textarea [(ngModel)]="missingFieldValues.notes" name="notes"></ion-textarea>
    </ion-item>
  </ion-list>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button (click)="submitForm()" [disabled]="!sendForSignatureForm.form.valid">
          {{ submitButtonText }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
